import { Component, Input } from '@angular/core';

import { SelectedAccountService } from '../../services/appstate/selected-account.service';

import { Account } from '../../models/account';
import { naturalSortBy } from 'manager/services/utility/utility';

@Component({
  selector: 'latch-account-switcher',
  templateUrl: './account-switcher.component.html',
  styleUrls: ['./account-switcher.component.scss'],
})
export class AccountSwitcherComponent {

  @Input()
  set accounts(value: Account[]) {
    this.sortedAccounts = naturalSortBy(value, 'name');
  }
  @Input()
  selectedAccount!: Account;

  dropdownVisible = false;
  sortedAccounts: Account[] = [];

  constructor(
    private selectedAccountService: SelectedAccountService,
  ) { }

  public handleChangeAccount(account: Account) {
    if (account !== this.selectedAccount) {
      this.selectedAccountService.selectAccount(account.uuid);
      // Current architecture around SelectedAccountService makes it non-trivial for dependent
      // services to pick up selected account changing. Since this is primarily an internal feature
      // right now, we just take a shortcut and refresh the page to clear app state.
      location.href = '/console/account';
    }
  }

  toggleDropdown(event: Event) {
    this.dropdownVisible = !this.dropdownVisible;
    event.preventDefault();
  }
}
