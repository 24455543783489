import { Injectable } from '@angular/core';
import { Account } from 'manager/models/account';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AccountService } from './account.service';

@Injectable({ providedIn: 'root' })
export class FilteredAccountsService {

  constructor(
    private accountService: AccountService
  ) { }

  /**
   * Returns the accounts that either have at least one building in them or is currently selected.
   */
  public getFilteredAccounts(): Observable<Account[]> {
    return this.accountService.getAccounts().pipe(
        map(accounts => accounts.filter(account => account.buildings?.length)),
    );
  }

}
